<template>
  <div class="work-card">
    <div class="outer">
      <div class="text-info-box">
        <div class="card-content">
          <h4>{{ work.name }}</h4>
          <p class="m-0">{{ work.description }}</p>
        </div>
      </div>
      <div class="inner">
        <img
          v-if="work.image && !JSON.parse(work.image)[0].includes('.mp4')"
          :src="`${stroageUrl}/${JSON.parse(work.image)[0]}`"
          alt
        />
        <video
          loop
          @mouseover="$refs.workVid.play()"
          @mouseout="$refs.workVid.pause()"
          ref="workVid"
          v-if="work.image && JSON.parse(work.image)[0].includes('.mp4')"
        >
          <source :src="`${stroageUrl}/${JSON.parse(work.image)[0]}`" type="video/mp4" />
        </video>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['work'],
  data () {
    return {
      stroageUrl: process.env.VUE_APP_STORAGE_URL
    }
  },
  methods: {
    playVid () {
    }
  }

}
</script>

<style lang="scss" scoped>
.work-card {
  height: 100%;
  position: relative;
  overflow: hidden;
  cursor: pointer;

  .outer {
    position: relative;
    &:before {
      display: block;
      content: "";
      width: 100%;
      padding-top: (1 / 1) * 100%;
    }
    > .inner {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }

  .text-info-box {
    background: transparent;
    pointer-events: none;
    position: absolute;
    width: 100%;
    transition: all 0.5s cubic-bezier(0.86, 0, 0.07, 1);
    height: 100%;
    z-index: 100;
    top: 0;
    .card-content {
      position: absolute;
      transform: translateY(calc(100% - 0px));
      padding: 22px;
      bottom: 0;
      width: 100%;
      transition: all 0.5s cubic-bezier(0.86, 0, 0.07, 1);
      h4 {
        text-shadow: 1px 1px 5px #000;
      }
    }
  }
  img,
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    background: #000;
  }

  &:hover {
    .card-content {
      transform: translateY(0);
    }
    .text-info-box {
      background: rgba($color: #000, $alpha: 0.5);
    }
  }
}
</style>
